<template>
  <div>
    <b-card-code
      :title="'Update Translation of Brand: ' + nameCat"
      :before-change="validationForm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-row>
                <!-- translation language -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-group
                      label="Language"
                      label-for="language"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        :id="'language'"
                        v-model="tranLocaleSelect"
                        :state="tranLocaleSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="localeOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- translation name -->
                <b-col md="6">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        :id="'TranslationName'"
                        v-model="translations.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- translation description -->
                <b-col cols="12">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <vue-editor
                        :id="'translationDescription'"
                        v-model="translations.description"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-button variant="primary" @click.prevent="validationForm"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { VueEditor } from 'vue2-editor'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
export default {
  components: {  
    BCardCode,
    VueEditor,
  },
  mixins: [heightTransition],
  props: ['id', 'language'],
  data() {
    return {
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      translations: {
        locale: '',
        name: '',
        description: '',
      },
      tranID: 0,
      tranLocaleSelect: {
        text: '',
        value: '',
      },
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      nameCat: '',
    }
  },
  methods: {
    // submit offer API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.translations.locale = this.tranLocaleSelect.value

          axios
            .put('brands-translations/' + this.tranID, this.translations)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    axios
      .get('brands/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.nameCat = data.translation[0].name
        for (let ind in data.translation) {
          if (this.language == data.translation[ind].locale) {
            this.translations.locale = data.translation[ind].locale
            this.translations.name = data.translation[ind].name
            this.translations.description = data.translation[ind].description
            if (data.translation[ind].locale == 'en') {
              this.tranLocaleSelect.text = 'English'
              this.tranLocaleSelect.value = 'en'
            } else {
              this.tranLocaleSelect.text = 'Arabic'
              this.tranLocaleSelect.value = 'ar'
            }
            this.tranID = data.translation[ind].id
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>
 
<style lang="scss">
</style>

